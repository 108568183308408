const Logo = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M281 177V97" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <path d="M281 499L281 365" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <path d="M281 311L281 257" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <path d="M172 291L172 211" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <path d="M280.671 97L172 131" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <path d="M429.673 130.993L321 97" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <path d="M406.412 197.717L321 171" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <path d="M406.412 237.717L321 211" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <path d="M442.638 471.031L342 342" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <path d="M177.74 365.172C175.074 362.002 170.342 361.594 167.172 364.26C164.002 366.926 163.594 371.658 166.26 374.828L177.74 365.172ZM286.632 494.641L177.74 365.172L166.26 374.828L275.152 504.296L286.632 494.641Z" className="fill-black dark:fill-darkAccent"/>
            <path d="M429.901 370L321 499.461" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <path d="M280.671 177L172 211" className="stroke-black dark:stroke-darkAccent" strokeWidth="15"/>
            <path d="M280.671 257L172 291" className="stroke-black dark:stroke-darkAccent" strokeWidth="15"/>
            <path d="M434.797 295.158C438.751 293.921 440.953 289.714 439.716 285.761C438.48 281.808 434.272 279.605 430.319 280.842L434.797 295.158ZM430.319 280.842L167.761 362.972L172.239 377.288L434.797 295.158L430.319 280.842Z" className="fill-black dark:fill-darkAccent" />
            <path d="M313.5 300C313.5 304.142 316.858 307.5 321 307.5C325.142 307.5 328.5 304.142 328.5 300L313.5 300ZM328.5 300L328.5 97L313.5 97L313.5 300L328.5 300Z" className="fill-black dark:fill-darkAccent"/>
            <path d="M321 499L321 348" className="stroke-black dark:stroke-darkAccent" strokeWidth="15" strokeLinecap="round"/>
            <circle cx="300.5" cy="300.5" r="262.5" className="stroke-black dark:stroke-darkAccent" strokeWidth="15"/>
        </svg>
    )
} 

export default Logo